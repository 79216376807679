import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_header_homepage()
})

function dhsv_vc_header_homepage() {
  $('.dhsv_vc_header_homepage').each(function () {
    let $line = $('.js-line')
    let $logo = $('.js-header-logo')
    let scrollPercentage = undefined
    let velocity = 0.5
    $(window).on('scroll', function () {
      let offset = $line.offset().top - $(window).height() / 1.5
      if ($(window).scrollTop() > offset) {
        scrollPercentage =
          (($(window).scrollTop() - offset) / $line.height()) * velocity
        if (scrollPercentage > 1) {
          $line.find('div').css('height', $line.height())
        } else {
          $line.find('div').css('height', $line.height() * scrollPercentage)
        }
      } else {
        $line.find('div').css('height', 0)
      }

      if ($(window).scrollTop() > 0) {
        $logo.addClass('is-sticky')
      } else {
        $logo.removeClass('is-sticky')
        $logo.removeClass('no-animation')
      }
    })

    if ($(window).scrollTop() > 0) {
      $logo.addClass('is-sticky')
      $logo.addClass('no-animation')
    } else {
      $logo.removeClass('is-sticky')
    }

    let scrollTop = $(this).offset().top + $(this).outerHeight()

    $line.on('click', function () {
      $('html, body').animate({ scrollTop: scrollTop }, 750)
    })

    /*let offsetTop = $(this).find('.js-header-row').offset().top
    document.documentElement.style.setProperty('--offsetTop', `${offsetTop}px`)*/
  })
}
