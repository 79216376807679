import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_accordion()
})

function dhsv_vc_accordion() {
  $('.dhsv_vc_accordion').on('click', '.card', function (e) {
    e.preventDefault()
    if ($(this).hasClass('active')) {
      $(this).removeClass('active').find('.collapse ').slideUp()
    } else {
      $('.dhsv_vc_accordion .card.active .collapse ').slideUp()
      $('.dhsv_vc_accordion .card.active').removeClass('active')
      $(this).addClass('active').find('.collapse ').slideDown()
    }
    return false
  })
}
