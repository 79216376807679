/* Don't forget to add string 'products' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
// import * as _ from 'lodash'
const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  maxNumPages: 0,
  lastPost: '',
  term_id: null,
  id: '',
  posts: [],
  content: '',
  perPage: 5,
  activePage: 1,
}
const LOAD_POSTS_DATA = 'LOAD_POSTS_DATA'
const LOAD_POSTS_DATA_SUCCESS = 'LOAD_POSTS_DATA_SUCCESS'
const LOAD_POSTS_DATA_FAILURE = 'LOAD_POSTS_DATA_FAILURE'
const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_POSTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_POSTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...payload.posts],
        maxNumPages: parseInt(payload.maxNumPages),
      }

    case LOAD_POSTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    case SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: payload.activePage,
      }

    default:
      return state
  }
}

export const setActivePage = activePage => dispatch => {
  dispatch({ type: SET_ACTIVE_PAGE, payload: { activePage } })
}
export const loadPosts = ({ postType, postCategories, postsPerPage }) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_POSTS_DATA })
  // Rest API Params
  const { perPage, activePage } = getState().posts

  let params = {
    post_type: postType,
    post_categories: postCategories,
    perPage: postsPerPage ? postsPerPage : perPage,
    paged: activePage,
  }
  let apiBaseUrl = WP_API_BASE
  // const htmlLang = document.getElementsByTagName('html')[0].getAttribute('lang')

  // if (window.siteLang.format) {
  //   const format = window.siteLang.format
  //   if (parseInt(format) === 3) {
  //     params.lang = window.siteLang.current
  //   } else if (window.siteLang.current !== window.siteLang.default) {
  //     apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  //   }
  // }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      // eslint-disable-next-line no-prototype-builtins
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  // if (htmlLang == 'de-de') {
  //   url_params = url_params + '&lang=de'
  // } else if (htmlLang == 'en-us') {
  //   url_params = url_params + '&lang=en'
  // }

  // console.log('request', `${apiBaseUrl}posts${url_params}`) //eslint-disable-line
  return fetch(`${apiBaseUrl}posts${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(responseData => {
      dispatch({
        type: LOAD_POSTS_DATA_SUCCESS,
        payload: {
          posts: responseData.posts,
          maxNumPages: responseData.maxNumPages,
        },
      })

      return responseData
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_POSTS_DATA_FAILURE,
      })
    })
}
