import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_text_in_columns()
})

function dhsv_vc_text_in_columns() {
  $('.dhsv_vc_text_in_columns').each(function () {
    $('.js-text-col').hover(
      function () {
        $(this).find('.dhsv_vc_text_in_columns__hidden').slideDown(200)
      },
      function () {
        $(this).find('.dhsv_vc_text_in_columns__hidden').slideUp(200)
      }
    )
  })
}
