import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_textblock()
})

$(window).on('resize', function () {
  dhsv_vc_textblock()
})

function dhsv_vc_textblock() {
  $('.dhsv_vc_textblock__img').each(function () {
    let imgoffset = $(this).offset().left

    document.documentElement.style.setProperty(
      '--beforeWidth',
      `${imgoffset}px`
    )
  })
}
