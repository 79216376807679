const dhsvCommon = {
  init() {
    this.showSubmenu()
    this.mobileMenuOpen()
    this.addMobileSubmenuToggle()
    this.fixedHeader()
    this.addAnchorsNav()
    this.setActiveSection()
    this.scrollToSection()
    this.submenuWidth()
    this.showHideSubmenu()
    this.mobileSpace()

    // init functions on resize
    $(window).on('resize', function () {
      dhsvCommon.showSubmenu()
      dhsvCommon.submenuWidth()
      dhsvCommon.showHideSubmenu()
      dhsvCommon.mobileSpace()

      if ($(window).width() > 1024) {
        $('.js-menu-toggle').removeClass('is-active')
        $('.js-nav-primary').removeAttr('style')
      }
    })
  },
  /**
   * Fixed Header
   */
  fixedHeader() {
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 0) {
        $('.banner').addClass('is-sticky')
      } else {
        $('.banner').removeClass('is-sticky')
      }
    })
    $(document).ready(function () {
      if ($('.dhsv_vc_header_content_page--dark').length) {
        $('.banner').addClass('banner--dark')
      }

      if ($(window).scrollTop() > 0) {
        $('.banner').addClass('is-sticky')
      } else {
        $('.banner').removeClass('is-sticky')
      }
    })
  },

  /**
   * Header height mobile space
   */
  mobileSpace() {
    let headerHeight = $('.banner').outerHeight()
    document.documentElement.style.setProperty(
      '--mobileSpace',
      `${headerHeight}px`
    )
  },

  /**
   * Show submenu on hover menu item
   */
  showSubmenu() {
    if ($(window).width() > 767) {
      $('.nav .menu-item-has-children').hover(
        function () {
          $(this).addClass('is-active')
          $(this).find('.sub-menu').first().addClass('is-active')
        },
        function () {
          $(this).removeClass('is-active')
          $(this).find('.sub-menu.is-active').first().removeClass('is-active')
        }
      )
    }
  },

  /**
   * Submenu width
   */
  submenuWidth() {
    if ($(window).width() > 1024) {
      $('.sub-menu li').each(function () {
        if ($(this).outerWidth() > 340) {
          $(this).css('width', '280px')
          $(this).find('a').css('white-space', 'normal')
        }
      })
    }
  },

  /**
   * Open menu on button click on mobile
   */
  mobileMenuOpen() {
    $('body').on('click', '.js-menu-toggle', function () {
      $(this).toggleClass('is-active')
      $('.banner').toggleClass('menu-opens')
      $('.sub-menu').removeClass('is-active')
    })
  },

  /**
   * Add submenu toggle button for mobile
   */
  addMobileSubmenuToggle() {
    let $items = $('.nav .menu-item-has-children')
    if ($items.length) {
      $items.each(function () {
        $(this).append(
          '<span class="toggle-submenu js-open-submenu"><i class="ion-chevron-right"></i></span>'
        )
        $(this)
          .find('.sub-menu')
          .prepend(
            '<li class="back-menu js-close-submenu"><i class="ion-chevron-left"></i>Zurück</li>'
          )
      })
    }
  },
  /**
   * Show / Hide submenu on mobile
   */
  showHideSubmenu() {
    if ($(window).width() < 1025) {
      $('body').on('click', '.js-open-submenu', function () {
        $(this).closest('li').find('.sub-menu').addClass('is-active')
      })

      $('body').on('click', '.js-close-submenu', function () {
        $(this).closest('.sub-menu').removeClass('is-active')
      })
    }
  },

  /**
   * Add Anchors sidebar nav
   */
  addAnchorsNav() {
    let $container = $('.js-anchors-nav')
    let anchors = ''
    if ($('[data-anchor-id]').length) {
      anchors += '<ul class="anchors-nav__list">'
      $('[data-anchor-id]').each(function (i) {
        anchors +=
          '<li class=' +
          $(this).attr('data-anchor-id') +
          '>' +
          '<a href="' +
          $(this).attr('data-anchor-id') +
          '" class="js-anchor-item"><span class="number-has-border">' +
          (i + 1) +
          '</span><span class="label"><span class="name">' +
          $(this).attr('data-anchor-name') +
          '</span></span></a></li>'
      })
      anchors += '</ul>'
      $container.html(anchors)
    }

    $(window).on('scroll', function () {
      if ($(window).scrollTop() > $('.main div').eq(1).outerHeight()) {
        $container.removeClass('inactive')
      } else {
        $container.addClass('inactive')
      }
    })
  },

  /**
   * Set active section
   */
  setActiveSection() {
    $(window).on('scroll', function () {
      let current = ''
      // get current section
      $('[data-anchor-id]').each(function (i, section) {
        let sectionTop = section.offsetTop + section.offsetHeight / 2
        let offsetTop = pageYOffset + window.innerHeight / 1.5

        if (offsetTop >= sectionTop) {
          current = section.getAttribute('data-anchor-id')
        }
      })
      // set active van link
      $('body .anchors-nav__list li').each(function (i, item) {
        item.classList.remove('active')
        if (item.classList.contains(current)) {
          item.classList.add('active')
        }
      })
    })
  },

  /**
   * Scroll to section
   */
  scrollToSection() {
    $('body').on('click', '.js-anchor-item', function (e) {
      e.preventDefault()
      $('html,body').animate(
        {
          scrollTop: $(
            '[data-anchor-id="' + $(this).attr('href') + '"]'
          ).offset().top,
        },
        750
      )
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
